@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Inter", sans-serif;
  
}


